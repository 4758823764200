import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Products from './views/Products/Products';
import ProductDetails from './views/ProductDetails/ProductDetails';
import Contact from './views/Contact/Contact';
import Admin from './views/Admin/Admin';
import Login from './views/Login/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewPage from './views/NewPage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/products" element={<Products/>} />
            <Route path="/products/:productId" element={<ProductDetails />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<NewPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
