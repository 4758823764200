import React, { useState, useEffect } from "react";
import "./About.css";
import Heading from "../../components/Heading/Heading";
import { Container, Row, Col } from "react-bootstrap";
import { TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
import { db } from "../../firebase";
import { onValue, ref } from "firebase/database";

function About() {
  const [about, updateAbout] = useState("");

  useEffect(() => {
    const query = ref(db, "About");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      updateAbout(data);
    });
  }, []);

  return (
    <div>
      {/* <Heading choice="about" /> */}
      <Container className="aboutContent">
        <p className="aboutPageTitle">About Us</p>
        <div className="divider"></div>
        <Col>
          <Row className="aboutSection">
            <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
              <img
                src={require("../../img/a1.png")}
                alt="about"
                className="aboutPic"
              />
            </Col>
            <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }} className="aboutSectionDescription">
                <p>
                    Welcome to Boaters Bay, Singapore's premier maritime one-stop
                    online shop! At Boaters Bay, we are dedicated to providing
                    top-quality products and exceptional service to maritime
                    enthusiasts and professionals alike.
                </p>
            </Col>
          </Row>
          <Row className="aboutSection">
            <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }} className="aboutSectionDescription">
              <ul>
                <li>
                  <b>Pleasure Craft Cleaning Products:</b><br/>Keep your boats in pristine
                  condition with our range of high-quality cleaning solutions.
                </li>
                <li>
                  <b>Maintenance Products:</b><br />Ensure your vessels are always in
                  top-notch shape with our effective and reliable maintenance
                  items.
                </li>
              </ul>
            </Col>
            <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
              <img
                src={require("../../img/a2.png")}
                alt="about"
                className="aboutPic"
              />
            </Col>
          </Row>
          <Row className="aboutSection">
            <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
              <img
                src={require("../../img/a3.png")}
                alt="about"
                className="aboutPic"
              />
            </Col>
            <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }} className="aboutSectionDescription">
              Our mission is to simplify and enhance your maritime experience by
              providing a convenient, reliable, and comprehensive online
              shopping destination. We are committed to delivering outstanding
              value through high-quality products, competitive pricing, and
              exceptional customer service.
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
  );
}

export default About;
