import React from "react";
import { Home } from "./Home/Home";
import About from "./About/About";
import Heading from "../components/Heading/Heading";
import Footer from "./Footer/Footer";
import ContactIcons from "../components/ContactIcons";

function NewPage() {
    return <div>
        <Heading />
        <Home />
        <About id="#about" />
        <Footer />
        <ContactIcons />
    </div>
};

export default NewPage;