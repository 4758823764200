import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "./Products.css";
import Heading from "../../components/Heading/Heading";
import FilterBar from "../../components/FilterBar/FilterBar";
import { db } from "../../firebase";
import { onValue, ref } from "firebase/database";
import ContactIcons from "../../components/ContactIcons";

function Products() {
  const [title, updateTitle] = useState("Products");
  const [products, updateProducts] = useState([]);
  const [filteredProducts, updateFilteredProducts] = useState({});
  const [productCategories, updateProductCategories] = useState(["All Products"]);
  const navigate = useNavigate();

  useEffect(() => {
    const query = ref(db, "Products");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const productcats = ["All Products", ...Object.keys(data)];
      updateProductCategories(productcats);
      updateProducts(data);
      var filteredProductsTemp = {};
      for (var i = 0; i < productcats.length; i++) {
          if (productcats[i] === "All Products") {
              continue;
          }
          filteredProductsTemp = {...filteredProductsTemp, ...data[productcats[i]]}
          if (i === productcats.length - 1) {
            updateFilteredProducts(filteredProductsTemp);
          }
      }
    });
  }, []);

  function handleFilteredProducts(filter) {
      if (filter === "All Products") {
        var filteredProductsTemp = {};
        for (var i = 0; i < productCategories.length; i++) {
            if (productCategories[i] === "All Products") {
                continue;
            }
            filteredProductsTemp = {...filteredProductsTemp, ...products[productCategories[i]]}
        }
        updateFilteredProducts({...filteredProductsTemp});
      } else {
        updateFilteredProducts({...products[filter]});
      }
  }

  return (
    <div>
      <Heading />
      <Container fluid className="cardGroup">
        <FilterBar options={productCategories} callback={handleFilteredProducts} />
        <p className="pageTitle product-page-title">{title}</p>
        <Row className="cardRow">
          {filteredProducts && Object.keys(filteredProducts).map((productId, idx) => {
            return (
              <Col lg={4} sm={12} className="cardCol" key={idx}>
                <Card className="card" onClick={() => navigate("/products/" + productId)}>
                  <Card.Img variant="top" style={{height: 270, width: "auto", objectFit: "contain"}} src={filteredProducts[productId]?.photo ? filteredProducts[productId]?.photo : require("../../img/boat.jpg")} />
                  <Card.Body>
                    <Card.Title>{filteredProducts[productId]?.name}</Card.Title>
                    <Card.Text>$ {filteredProducts[productId]?.price}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <ContactIcons />
    </div>
  );
}

export default Products;
