import React, { useEffect, useState, useRef } from "react";
import "./Contact.css";
import Heading from "../../components/Heading/Heading";
import { Button, Col, Form, Row } from "react-bootstrap";
import { EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
import emailjs from "@emailjs/browser";
import ContactIcons from "../../components/ContactIcons";

function Contact() {
    const [formValidated, updateFormValidation] = useState(false);
    const [formFields, updateFormFields] = useState({});
    const formNameRef = useRef();
    const formEmailRef = useRef();
    const formTextRef = useRef();

    useEffect(() => {
        if (!(formFields.name && formFields.email && formFields.message)) {
            updateFormValidation(false);
        } else {
            updateFormValidation(true);
        }
    }, [formFields]);

    function sendEmail(e) {
        e.preventDefault();
        const serviceId = "service_txo5psh";
        const templateId = "template_fstpnwg";
        const publicKey = "oZQo9QkSlBlW66Qig";
        const emailContent = {
            name: formFields.name,
            email: formFields.email,
            message: formFields.message
        };
        emailjs.send(serviceId, templateId, emailContent, { publicKey });
        updateFormFields({});
        formNameRef.current.value = "";
        formEmailRef.current.value = "";
        formTextRef.current.value = "";
    }

    return <div className="contact">
        <Heading />
        <div className="contactPageTitle">Contact Us</div>
        <div className="divider"></div>
        <div className="contactBody">
            <Row>
                <Col md={6} xs={12}>
                    <Form validated={formValidated} onSubmit={(e) => sendEmail(e)}>
                        <Form.Group className="contactForm">
                            <p><b>Drop Us A Line!</b></p>
                            <Form.Control ref={formNameRef} type="name" placeholder="Name*" value={formFields?.name} onChange={(e) => updateFormFields({...formFields, name: e.target.value})} />
                            <Form.Control ref={formEmailRef} type="email" placeholder="Email*" value={formFields?.email} onChange={(e) => updateFormFields({...formFields, email: e.target.value})} />
                            <Form.Control ref={formTextRef} as="textarea" rows={3} placeholder="Message*" value={formFields?.message} onChange={(e) => updateFormFields({...formFields, message: e.target.value})} />
                            <div>
                                <Button id="contactSubmitButton" type="submit" disabled={!formValidated}>Submit</Button>
                            </div>
                        </Form.Group>
                    </Form>
                </Col>
                <Col md={6} xs={12}>
                    <p className="contactBodyText">We'd love to hear from you! Whether you have questions about our products, need support with your order, or want to give us feedback, our team at Boaters Bay is here to help.</p>
                    <p>
                        <br />
                        <TelephoneFill /> +65 9669 4673
                        <br />
                        <EnvelopeFill /> boatersbaysg@gmail.com
                    </p>
                </Col>
            </Row>
        </div>
        <ContactIcons />
    </div>;
};

export default Contact;