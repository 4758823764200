import React, { useState } from "react";
import "./FilterBar.css";
import { Drawer } from "@mui/material";
import { ThreeDotsVertical } from "react-bootstrap-icons";

function FilterBar(props) {
  const { options, callback } = props;
  const [drawerOpen, updateDrawerOpen] = useState(false);

  return (
    <div className="filterbar-container">
      <button className="filterbar-button" onClick={() => updateDrawerOpen(true)}>
        <ThreeDotsVertical /> Categories
      </button>
      <Drawer open={drawerOpen} onClose={() => updateDrawerOpen(false)}>
        <p className="filterbar-menu-title">Categories</p>
        {options.map((option, idx) => (
          <div
            className="filterbar-option"
            value={option}
            key={idx}
            onClick={(e) => {
              callback(e.target.innerHTML);
              updateDrawerOpen(false);
            }}
          >
            {option}
          </div>
        ))}
      </Drawer>
    </div>
  );
}

export default FilterBar;
