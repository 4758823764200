import React from "react";

function WhatsappIcon() {
    return <div
        onClick={() => window.open("https://wa.me/6596694673")}
        className="contactIcon"
        style={{
            position: "fixed",
            right: 20,
            bottom: 20,
        }}
        >
            <img width={70} src={require("../img/whatsapp.png")} alt="whatsapp" />
        </div>;
};

export default WhatsappIcon;