import React from "react";
import EmailIcon from "./EmailIcon";
import WhatsappIcon from "./WhatsappIcon";

function ContactIcons() {
    return <div>
        <EmailIcon />
        <WhatsappIcon />
    </div>;
};

export default ContactIcons;