import React from "react";
import { useNavigate } from "react-router-dom";

function EmailIcon() {
    const navigate = useNavigate();

    return <div
        onClick={() => navigate("/contact")}
        className="contactIcon"
        style={{
            position: "fixed",
            right: 20,
            bottom: 100,
        }}
        >
            <img width={70} src={require("../img/email.png")} alt="email" />
        </div>;
};

export default EmailIcon;