import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase";
import { onValue, ref as dbRef, set } from "firebase/database";
import { uploadBytes, ref as storageRef, getDownloadURL, deleteObject } from "firebase/storage";
import { Accordion, Button, Container, Modal } from "react-bootstrap";

const DEFAULT_NEW_PRODUCT = {
    name: "",
    category: "",
    description: "",
    price: 0,
    photo: null,
}

function EditProduct() {
    const [products, updateProducts] = useState({});
    const [popup, updatePopup] = useState(false);
    const [newProduct, updateNewProduct] = useState(DEFAULT_NEW_PRODUCT);
    // set(dbRef(db, "Products"), {
    //     Maintenance: {
    //         M1: {
    //             name: "Boat 1",
    //             description: "Boat 1",
    //             price: 100,
    //             photo: ""
    //         },
    //     },
    //     "Boat Care": {
    //         C1: {
    //             name: "Boat 1",
    //             description: "Boat 1",
    //             price: 100,
    //             photo: ""
    //         },
    //         C2: {
    //             name: "Boat 1",
    //             description: "Boat 1",
    //             price: 100,
    //             photo: ""
    //         },
    //         C3: {
    //             name: "Boat 1",
    //             description: "Boat 1",
    //             price: 100,
    //             photo: ""
    //         }
    //     },
    // });

    useEffect(() => {
        const query = dbRef(db, "Products");
        return onValue(query, (snapshot) => {
          const data = snapshot.val();
          updateProducts(data);

          // update default new product category
          if (data) {
            updateNewProduct({...newProduct, category: Object.keys(data)[0]});
          }
        });
      }, []);

    function updateItem(newProducts) {
        updateProducts({...newProducts});
        set(dbRef(db, "Products"), {...newProducts});
    };

    function resetNewProduct() {
        if (products) {
            updateNewProduct({...DEFAULT_NEW_PRODUCT, category: Object.keys(products)[0]});
        } else {
            updateNewProduct(DEFAULT_NEW_PRODUCT);
        }
    };

    function closePopup() {
        updatePopup(false);
        resetNewProduct();
    };

    function uploadNewProduct() {
        // get category and uuid
        const cat = newProduct.category;
        const uuid = crypto.randomUUID();

        // make a copy of all products
        let updatedProducts = products;

        // upload image and save the link
        const imageRef = storageRef(storage, `images/${cat}/${uuid}`);
        uploadBytes(imageRef, newProduct.photo).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                updatedProducts[cat][uuid] = {...newProduct, photo: url};
                set(dbRef(db, "Products"), {...updatedProducts});
                closePopup();
            });
        });
    }

    return (
        <div>
            <Container className="editproduct-add-product">
                <Button onClick={() => updatePopup(true)}>
                    Add product
                </Button>
            </Container>
            <Modal show={popup} onHide={closePopup}>
                <Modal.Header>
                    <p className="editproduct-modal-header">Add New Product</p>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <span className="editproduct-modal-body-label">Name: </span>
                        <input className="editproduct-modal-body-input" defaultValue={newProduct.name} onChange={(e) => updateNewProduct({...newProduct, name: e.target.value})} />
                    </div>
                    <div>
                        <span className="editproduct-modal-body-label">Category: </span>
                        <select className="editproduct-modal-body-input" onChange={(e) => updateNewProduct({...newProduct, category: e.target.value})}>
                            {products && Object.keys(products).map((cat, id) => <option key={id} value={cat}>{cat}</option>)}
                        </select>
                    </div>
                    <div>
                        <span className="editproduct-modal-body-label">Description: </span>
                        <textarea className="editproduct-modal-body-input" defaultValue={newProduct.description} onChange={(e) => updateNewProduct({...newProduct, description: e.target.value})} />
                    </div>
                    <div>
                        <span className="editproduct-modal-body-label">Price: </span>
                        <input className="editproduct-modal-body-input" type="number" defaultValue={newProduct.price} onChange={(e) => updateNewProduct({...newProduct, price: e.target.value})} />
                    </div>
                    <div>
                        <span className="editproduct-modal-body-label">Photo: </span>
                        <input className="editproduct-modal-body-input" type="file" defaultValue={newProduct.photo} onChange={(e) => updateNewProduct({...newProduct, photo: e.target.files[0]})} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={closePopup}>Cancel</Button>
                    <Button onClick={uploadNewProduct}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
            {products && Object.keys(products).map((cat) => {
                return (
                    <Container>
                        <p className="editproduct-category-label">{cat}</p>
                        <Accordion>
                            {Object.keys(products[cat]).map((itemId) => {
                                let itemInfo = products[cat][itemId];
                                let picture = null;
                                return (
                                    <Accordion.Item eventKey={itemId}>
                                        <Accordion.Header>{products[cat][itemId]["name"]}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="editproduct-field-group">
                                                <p>Name: </p>
                                                <input className="editproduct-field-input" defaultValue={itemInfo["name"]} onChange={(e) => {itemInfo["name"] = e.target.value;}} />
                                            </div>
                                            <div className="editproduct-field-group">
                                                <p>Description: </p>
                                                <textarea className="editproduct-field-input" defaultValue={itemInfo["description"]} onChange={(e) => {itemInfo["description"] = e.target.value;}} />
                                            </div>
                                            <div className="editproduct-field-group">
                                                <p>Price: </p>
                                                <input type="number" className="editproduct-field-input" defaultValue={itemInfo["price"]} onChange={(e) => {itemInfo["price"] = e.target.value;}} />
                                            </div>
                                            <div className="editproduct-field-group">
                                                <p>Photo: </p>
                                                <input
                                                    type="file"
                                                    className="editproduct-field-input"
                                                    id="editproduct-field-input"
                                                    value={picture}
                                                    onChange={(e) => {
                                                        picture = e.target.files[0];
                                                    }}
                                                />
                                                <p>Current photo: </p>
                                                {itemInfo["photo"]
                                                    ? (
                                                    <div>
                                                        <img width={200} src={itemInfo["photo"]} alt="image" />
                                                    </div>)
                                                    : <span>No photo yet</span>}
                                            </div>
                                            <div className="editproduct-field-button-group">
                                                <Button
                                                    variant="danger"
                                                    className="editproduct-field-button"
                                                    onClick={() => {
                                                        let newProducts = products;
                                                        let allItemsInCatWithoutDeleted = {};
                                                        Object.keys(products[cat]).forEach((id) => {
                                                            if (id !== itemId) {
                                                                allItemsInCatWithoutDeleted[id] = products[cat][id];
                                                            }
                                                        })
                                                        newProducts[cat] = allItemsInCatWithoutDeleted;
                                                        const imageRef = storageRef(storage, `images/${cat}/${itemId}`);
                                                        updateItem(newProducts);
                                                        deleteObject(imageRef);
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    variant="warning"
                                                    className="editproduct-field-button"
                                                    onClick={() => {
                                                        picture = null;
                                                        let newProducts = products;
                                                        const imageRef = storageRef(storage, `images/${cat}/${itemId}`);
                                                        uploadBytes(imageRef, picture).then((snapshot) => {
                                                            getDownloadURL(snapshot.ref).then(() => {
                                                                itemInfo["photo"] = ""
                                                                newProducts[cat][itemId] = itemInfo;
                                                                updateItem(newProducts);
                                                            });
                                                        });
                                                    }}
                                                >
                                                    Delete Current Photo and Update
                                                </Button>
                                                <Button
                                                    className="editproduct-field-button"
                                                    onClick={() => {
                                                        let newProducts = products;
                                                        const imageRef = storageRef(storage, `images/${cat}/${itemId}`);
                                                        uploadBytes(imageRef, picture).then((snapshot) => {
                                                            getDownloadURL(snapshot.ref).then((url) => {
                                                                itemInfo["photo"] = url;
                                                                newProducts[cat][itemId] = itemInfo;
                                                                updateItem(newProducts);
                                                                picture = null;
                                                            });
                                                        });
                                                    }}
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                                })
                            }
                        </Accordion>
                    </Container>
                );}
            )}
        </div>
    );
}

export default EditProduct;