import './Home.css';
import React from "react";
import { Button } from 'react-bootstrap';
import { ChevronDoubleRight, EnvelopeFill } from 'react-bootstrap-icons';

export function Home() {
    return <div className="home">
        {/* <Heading /> */}
        <div className='homeContent'>
            <h3 className='homeTitle'>Your one-stop shop for all<br />your boating needs!</h3>
            <div className='buttonGroup'>
                <Button variant="light" className='button' href='/products'>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <span style={{marginRight: 5}}>SHOP NOW</span>
                        <ChevronDoubleRight />
                    </div>
                </Button>
            </div>
            <div className='buttonGroup'>
                <Button variant="light" className='contact-button' href='/contact'>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <span style={{marginRight: 5}}>Contact Us</span>
                        <EnvelopeFill />
                    </div>
                </Button>
            </div>
        </div>
    </div>
}